import axiosInstance from "@/http";
const state = {};
const getters = {};
const mutations = {};
const actions = {
  async createIntent({ commit, state }, payload) {
    try {
      const url = `/intent`;
      const response = await axiosInstance.post(url, payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async updateIntent({ commit, state }, payload) {
    try {
      const url = `/intent/${payload._id}?session_id=${payload.session_id}&access_token=${payload.access_token}`;
      const response = await axiosInstance.put(url, payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async deleteIntent({ commit }, payload) {
    try {
      const response = await axiosInstance.delete(
        `/intent/${payload._id}?session_id=${payload.session_id}&access_token=${payload.access_token}`
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getUserIntents({ commit, state }, payload) {
    try {
      const url = `/intent?session_id=${payload.session_id}&access_token=${payload.access_token}&page=${payload.page}&limit=${payload.limit}`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getUserSurveys({ commit, state }, payload) {
    try {
      const url = `/survey?session_id=${payload.session_id}&access_token=${payload.access_token}&page=${payload.page}&limit=${payload.limit}`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getSingleIntent({ commit, state }, payload) {
    try {
      const url = `/intent/${payload._id}?session_id=${payload.session_id}&access_token=${payload.access_token}`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getUserSurveys({ commit, state }, payload) {
    try {
      const url = `/survey?session_id=${payload.session_id}&access_token=${payload.access_token}&page=${payload.page}&limit=${payload.limit}`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async createSurvey({ commit, state }, payload) {
    try {
      const url = `/survey`;
      const response = await axiosInstance.post(url, payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async updateSurvey({ commit, state }, payload) {
    try {
      const url = `/survey/${payload._id}`;
      const response = await axiosInstance.put(url, payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async deleteSurvey({ commit }, payload) {
    try {
      const response = await axiosInstance.delete(`/survey/${payload._id}`);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getSingleSurvey({ commit, state }, payload) {
    try {
      const url = `/survey/${payload._id}?session_id=${payload.session_id}&access_token=${payload.access_token}&page=${payload.page}&limit=${payload.limit}`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getSingleSurveyAnswers({ commit, state }, payload) {
    try {
      const url = `/survey-answer/${payload._id}?session_id=${payload.session_id}&access_token=${payload.access_token}&page=${payload.page}&limit=${payload.limit}`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async createNewSurveyQuestion({ commit, state }, payload) {
    try {
      const url = `/survey-question?session_id=${payload.session_id}&access_token=${payload.access_token}`;
      const response = await axiosInstance.post(url, payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async updateSurveyQuestion({ commit, state }, payload) {
    try {
      const url = `/survey-question/${payload._id}?session_id=${payload.session_id}&access_token=${payload.access_token}`;
      const response = await axiosInstance.put(url, payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async rearrangeSortIds({ commit, state }, payload) {
    try {
      const url = `/survey/sortIds?session_id=${payload.session_id}&access_token=${payload.access_token}`;
      const response = await axiosInstance.put(url, payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async deleteSurveyQuestion({ commit }, payload) {
    try {
      const response = await axiosInstance.delete(
        `/survey-question/${payload._id}?session_id=${payload.session_id}&access_token=${payload.access_token}`
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getSurveyQuestion({ commit }, payload) {
    try {
      const response = await axiosInstance.get(
        `/survey-question/${payload._id}?session_id=${payload.session_id}&access_token=${payload.access_token}`
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
