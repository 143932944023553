<template>
  <div>
    <div v-if="isLoading" class="d-flex justify-content-center">
      <Spinner
        variant="layout"
        message="Fetching Contacts... Hang on."
        :hasMessage="true"
      />
    </div>
    <div v-if="!isLoading && !errorOccurred && !hasExtendedSearch">
      <div class="d-block align-items-center px-2">
        <form
          class="input-group wb-input-group wb-input-group--small w-100 me-3"
          @submit.prevent="handleSearch(false)"
        >
          <input
            inputmode="text"
            v-model="searchQuery"
            type="tel"
            class="form-control wb-input"
            placeholder="Search Contact"
          />

          <a
            href="javascript:void(0)"
            class="input-group-text"
            @click="handleSearch(false)"
          >
            <i class="bi bi-search"></i>
          </a>
        </form>
      </div>
    </div>
    <div v-if="!isLoading && filteredContacts.length > 0">
      <div class="wb-list wb-list--small">
        <div
          v-for="contact in filteredContacts"
          :key="contact._id"
          class="my-3 wb-contact__item px-2"
          @click="selectContact(contact)"
        >
          <div class="me-1">
            <img
              :src="`https://api.dicebear.com/6.x/initials/svg?seed=${
                contact.name || 'name'
              }&backgroundColor=3bb855`"
              class="wb-image-icon wb-image-icon--standard wb-image-icon--rounded"
              alt=""
            />
          </div>
          <div>
            <div class="text__body--smallest">
              {{ contact.name }}
            </div>
            <div class="text__body--smaller">
              {{ contact.number }}
            </div>
          </div>
        </div>
        <LoadMoreButton
          v-if="totalPages > 1 && totalPages !== currentPage && !isLoading"
          :isLoading="isLoadingMore"
          @startLoading="loadMore"
          loadingMessage="Load More Contacts"
        />
      </div>
      <div class="my-2 text-center">
        <a
          href="javascript:void(0)"
          class="text__body--small text--link text-decoration-none"
          @click="addNewContact"
        >
          Add New Contact <i class="bi bi-plus"></i>
        </a>
      </div>
    </div>
    <div class="text-center" v-if="errorOccurred">
      <ErrorOccurred
        message=" An error occurred while fetching contacts."
        description="There was an error while getting your contacts"
        @retry="getContacts"
      />
    </div>
    <div
      class="text-center"
      v-if="!isLoading && filteredContacts.length == 0 && !errorOccurred"
    >
      <EmptyState
        title="No Contacts available "
        description="Contacts you save will appear here"
        :icon="require('@/assets/icons/contacts.svg')"
      />
      <div class="my-2 text-center">
        <a
          href="javascript:void(0)"
          class="text__body--small text--link text-decoration-none"
          @click="addNewContact"
        >
          Add New Contact <i class="bi bi-plus"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "@/utils/eventBus";
export default {
  name: "ContactList",
  props: {
    hasExtendedSearch: {
      type: Boolean,
      default: false,
    },
    extendedSearchQuery: {
      type: String,
      default: "",
    },
    activeContact: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showModal: false,
      contacts: [],
      searchQuery: "",
      isLoading: false,
      errorOccurred: false,
      isLoadingMore: false,
      totalPages: 0,
      currentPage: 1,
      limitPerPage: 50,
    };
  },
  mounted() {
    this.getContacts();
    eventBus.on("eventNewContactCreated", this.getContacts);
  },
  computed: {
    ...mapGetters(["activeBotInstance"]),
    botInstance() {
      return this.activeBotInstance ? this.activeBotInstance : false;
    },
    filteredContacts() {
      // const searchTerm = this.extendedSearchQuery
      //   ? this.extendedSearchQuery.trim()
      //   : this.searchQuery.trim();

      // if (searchTerm !== "") {
      //   return this.contacts.filter((c) => {
      //     const nameMatch =
      //       c.name && c.name.toLowerCase().includes(searchTerm.toLowerCase());
      //     const numberMatch =
      //       c.number &&
      //       c.number.toLowerCase().includes(searchTerm.toLowerCase());
      //     return nameMatch || numberMatch;
      //   });
      // }

      return this.contacts;
    },
  },
  methods: {
    ...mapActions(["getAllContacts", "searchWaabot"]),
    computeActiveContact() {
      const contactId = this.activeContact;
      if (contactId && contactId.trim() !== "") {
        const activeContact = this.contacts.find(
          (contact) => contact._id == contactId
        );
        if (activeContact) {
          this.selectContact(activeContact);
        }
      }
    },
    addNewContact() {
      eventBus.emit("eventCreateNewContact");
    },
    selectContact(contact) {
      this.$emit("selected", contact);
      this.searchQuery = "";
    },
    async loadMore() {
      if (this.isLoadingMore || this.currentPage >= this.totalPages) {
        return;
      }
      this.currentPage++;
      this.isLoadingMore = true;
      try {
        if (this.searchQuery && this.searchQuery.length > 0) {
          await this.handleSearch(true);
          return;
        }
        await this.getContacts(true);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingMore = false;
      }
    },
    async handleSearch(loadingMore) {
      if (
        this.isLoading ||
        (!this.searchQuery &&
          this.searchQuery.length === 0 &&
          this.searchQuery.trim() === "")
      ) {
        return;
      }
      this.isLoading = !loadingMore;
      this.errorOccurred = false;
      try {
        const { data } = await this.searchWaabot({
          session_id: this.botInstance.session_id,
          access_token: this.botInstance.access_token,
          page: this.currentPage,
          limit: this.limitPerPage,
          type: "contact",
          query: this.searchQuery,
        });
        this.contacts = data.data.results;
        this.totalPages = data.data.totalPages;
      } catch (error) {
        console.log(error);
        this.errorOccurred = true;
      } finally {
        this.isLoading = false;
      }
    },
    async getContacts(loadingMore = false) {
      if (this.isLoading || !this.activeBotInstance) {
        return;
      }
      this.isLoading = loadingMore ? false : true;
      this.errorOccurred = false;
      try {
        const { data } = await this.getAllContacts({
          session_id: this.botInstance.session_id,
          access_token: this.botInstance.access_token,
          page: this.currentPage,
          limit: this.limitPerPage,
        });
        if (loadingMore) {
          this.contacts = [...this.contacts, ...data?.data?.results];
        } else {
          this.contacts = data.data.results;
          this.totalPages = data.data.totalPages;
        }
        this.computeActiveContact();
      } catch (error) {
        console.log(error);
        this.errorOccurred = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    searchQuery(newVal, oldVal) {
      if (!newVal && newVal.length === 0 && newVal.trim() === "") {
        this.currentPage = 1;
        this.getContacts();
      }
    },
  },
};
</script>

<style></style>
