import axiosInstance from "@/http";
const state = {
  activeBotInstance: null,
  botsCount: 0,
  activeBotsCount: 0,
};
const getters = {
  activeBotInstance: (state) => state.activeBotInstance,
  botsCount: (state) => state.botsCount,
  activeBotsCount: (state) => state.activeBotsCount,
};
const mutations = {
  setInstance(state, activeBotInstance) {
    state.activeBotInstance = activeBotInstance;
  },
  setBotsCount(state, botsCount) {
    state.botsCount = botsCount;
  },
  setActiveBotsCount(state, activeBotsCount) {
    state.activeBotsCount = activeBotsCount;
  },
};
const actions = {
  // Get all bots
  async getAllBots({ commit, state }, getOnly = false) {
    try {
      const { data: response } = await axiosInstance.get("/bots");
      const bots = response.data.results;

      if (!getOnly) {
        if (bots.length === 0) {
          console.log("No Accounts");
          commit("setInstance", null);
          commit("setBotsCount", 0);
          return;
        }
        const activeBotId = state.activeBotInstance
          ? state.activeBotInstance._id
          : null;
        const activeBotIncluded = bots.some((bot) => bot._id === activeBotId);
        if (activeBotIncluded) {
          const activeBot = bots.find((bot) => bot._id === activeBotId);
          commit("setInstance", activeBot);
        }
        if (!state.activeBotInstance || !activeBotIncluded) {
          if (bots.length > 0) {
            const randomBot = bots[Math.floor(Math.random() * bots.length)];
            commit("setInstance", randomBot);
          } else {
            commit("setInstance", null);
          }
        }
      }

      commit("setBotsCount", bots.length);
      const activeBots = bots.filter((bot) => bot.settings.status === "ONLINE");
      commit("setActiveBotsCount", activeBots.length);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  // Toggle Message Send
  async toggleMessageSend({ commit }, bot) {
    try {
      const response = await axiosInstance.put(`/whatsapp/updateActions`, {
        session_id: bot.session_id,
        access_token: bot.access_token,
        reply_msgs: !bot.settings.actions.reply_msgs,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  // Delete Instance
  async deleteInstance({ commit }, bot) {
    try {
      const response = await axiosInstance.delete(`/bots/${bot._id}`);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  // Logout Instance
  async logoutBot({ commit }, bot) {
    try {
      const response = await axiosInstance.get(
        `/whatsapp/logout?session_id=${bot.session_id}&access_token=${bot.access_token}`
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  // Create Bot
  async createNewWhatsapp({ commit }, payload) {
    try {
      const response = await axiosInstance.post(
        `/whatsapp/createNewWhatsapp`,
        payload
      );
      const newBot = response.data.data;
      commit("setInstance", newBot);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async initializeWhatsapp({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/whatsapp/init`, payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  // Get Scan QR
  async getInstanceQr({ commit }, payload) {
    try {
      const response = await axiosInstance.get(
        `/whatsapp/scan?session_id=${payload.session_id}&access_token=${payload.access_token}&format=base64text`
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  // Get Instance Data
  async getInstanceInfo({ commit }, payload) {
    try {
      const response = await axiosInstance.get(
        `/whatsapp/instance?session_id=${payload.session_id}&access_token=${payload.access_token}&format=base64text`
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  // Update General Settings
  async updateGeneralSettings({ commit, dispatch }, payload) {
    try {
      const response = await axiosInstance.put(
        `/whatsapp/updateGeneral`,
        payload
      );
      await dispatch("getAllBots", false);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  // Update Action Settings
  async updateActionSettings({ commit, dispatch }, payload) {
    try {
      const response = await axiosInstance.put(
        `/whatsapp/updateActions`,
        payload
      );
      await dispatch("getAllBots", false);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  // Update Active Days
  async updateActiveDays({ commit, dispatch }, payload) {
    try {
      const response = await axiosInstance.put(
        `/whatsapp/updateActiveDays`,
        payload
      );
      await dispatch("getAllBots", false);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  // Update Active Hours
  async updateActiveHours({ commit, dispatch }, payload) {
    try {
      const response = await axiosInstance.put(
        `/whatsapp/updateActiveHours`,
        payload
      );
      await dispatch("getAllBots", false);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  // Set Webhook
  async setWebhook({ commit, dispatch }, payload) {
    try {
      const response = await axiosInstance.put(`/whatsapp/setWebhook`, payload);
      await dispatch("getAllBots", false);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
