<template>
  <div>
    <div class="dropdown">
      <button type="button" class="wb-shortcodes__list-button dropdown-toggle" data-bs-toggle="dropdown"
        aria-expanded="false" data-bs-auto-close="outside" ref="dropdown_button">
        Add Button <i class="bi bi-plus wb-shortcodes__list-icon"></i>
      </button>
      <form class="dropdown-menu wb-dropdown-menu wb-dropdown-menu--extend py-3 px-2" ref="dropdown_menu"
        @submit.prevent="addNewButton">
        <div class="form-group mb-2">
          <label class="wb-input-label">Button Type</label>
          <div class="dropdown-center">
            <button
              class="wb-select-dropdown wb-select-dropdown--small dropdown-toggle w-100 text-start text-capitalize"
              type="button" data-bs-toggle="dropdown" aria-expanded="false">
              {{ buttonType ? buttonType.name : "Select Button" }}
              <i class="bi bi-chevron-down"></i>
            </button>
            <ul
              class="dropdown-menu wb-dropdown-menu wb-dropdown-menu--relative wb-dropdown-menu--form dropdown-menu-end py-3 px-2">
              <li v-for="(item, index) in buttonTypes" :key="index">
                <a class="dropdown-item text-capitalize" :class="{
          'dropdown-item--active':
            buttonType && buttonType.title == item.title,
        }" href="javascript:void(0)" @click="setButtonType(item)">
                  <i :class="item.icon" class="text__body me-2"></i>
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="form-group mb-2">
          <div class="input-group wb-input-group wb-input-group--small w-100">
            <input type="text" class="form-control wb-input" id="name" placeholder="Button Text" v-model="buttonText" />
          </div>
        </div>
        <div class="form-group mb-2" v-if="buttonType">
          <div class="input-group wb-input-group wb-input-group--small w-100">
            <input :type="buttonType.title == 'urlButton' ? 'url' : 'tel'" class="form-control wb-input" id="name"
              v-model="buttonPayload" :placeholder="buttonType.title == 'urlButton'
          ? 'https://example.com'
          : buttonType.title == 'replyButton' ? 'keyword' : '2349012345678'
          " />
          </div>
        </div>
        <button type="submit" class="wb-button wb-button--small wb-button--primary w-100 mt-4"
          :disabled="formInvalid || buttonsLeft === 0">
          Add Button
        </button>
        <div class="text__body--smaller text-muted text-end mt-3">
          {{ buttonsLeft }} buttons left
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddButton",
  props: {
    buttonCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      buttonTypes: [
        {
          name: "Reply Button",
          title: "replyButton",
          icon: "bi bi-reply text-info",
        },
        {
          name: "Url Button",
          title: "urlButton",
          icon: "bi bi-box-arrow-up-right text-primary",
        },
        {
          name: "Call Button",
          title: "callButton",
          icon: "bi bi-telephone text-success",
        },
      ],
      buttonType: null,
      buttonText: "",
      buttonPayload: "",
    };
  },
  computed: {
    buttonsLeft() {
      return 20 - this.buttonCount;
    },
    formInvalid() {
      // if (this.buttonType.title !== "replyButton") {
      return this.buttonText.trim() == "" || this.buttonPayload.trim() == "";
      /*
      } else {
        return this.buttonText.trim() == "";
      }
      */
    },
  },
  created() {
    this.buttonType = this.buttonTypes[0];
  },
  methods: {
    resetForm() {
      this.buttonType = this.buttonTypes[0];
      this.buttonText = "";
      this.buttonPayload = "";
    },
    setButtonType(type) {
      this.buttonType = type;
    },
    addNewButton(contact) {
      const dropbutton = this.$refs.dropdown_button;
      const dropMenu = this.$refs.dropdown_menu;
      dropbutton.classList.remove("show");
      dropMenu.classList.remove("show");
      let buttonData;
      // if (this.buttonType.title !== "replyButton") {
      buttonData = {
        type: this.buttonType.title,
        title: this.buttonText,
        payload: this.buttonPayload,
      };
      // } else {
      //   buttonData = {
      //     type: this.buttonType.title,
      //     title: this.buttonText,
      //   };
      // }
      this.$emit("buttonAdded", buttonData);
      this.resetForm();
    },
  },
  watch: {
    buttonCount(newVal, oldVal) { },
  },
};
</script>

<style></style>
