<template>
  <div>
    <button
      class="wb-button wb-button--icon wb-button--plain"
      @click="getExports"
    >
      <i class="bi bi-download"></i>
    </button>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
export default {
  name: "Export",
  props: {
    exportType: {
      type: String,
      default: "",
    },
    surveyId: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const activeBotInstance = ref(null);
    const store = useStore();
    activeBotInstance.value = store.getters.activeBotInstance;
    const getExports = () => {
      if (props.exportType === "contact") {
        store.dispatch("exportContacts", {
          session_id: activeBotInstance.value.session_id,
          access_token: activeBotInstance.value.access_token,
        });
      }
      if (props.exportType === "intent") {
        store.dispatch("exportAutoReply", {
          session_id: activeBotInstance.value.session_id,
          access_token: activeBotInstance.value.access_token,
        });
      }
      if (props.exportType === "survey_quesions") {
        store.dispatch("exportSurveyQuestions", {
          session_id: activeBotInstance.value.session_id,
          access_token: activeBotInstance.value.access_token,
          surveyId: props.surveyId,
        });
      }
    };
    return {
      getExports,
    };
  },
};
</script>

<style></style>
