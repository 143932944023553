import { createStore } from "vuex";
import auth from "./modules/auth";
import instance from "./modules/instance";
import contact from "./modules/contact";
import attachment from "./modules/attachment";
import message from "./modules/message";
import chat from "./modules/chat";
import automation from "./modules/automation";
import utils from "./modules/utils";
import theme from "./modules/theme";
import channel from "./modules/channel";
import payment from "./modules/payment";
import createPersistedState from "vuex-plugin-persistedstate";
const store = createStore({
  plugins: [createPersistedState()],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    channel,
    instance,
    message,
    attachment,
    contact,
    automation,
    utils,
    chat,
    theme,
    payment,
  },
});
export default store;
