import axiosInstance from "@/http";
import { API_BASE } from "@/config";
const state = {};
const getters = {};
const mutations = {};
const actions = {
  async getCreatedBots({ commit, state }) {
    try {
      const response = await axiosInstance.get("/bots");
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async createBotAndChannel({ commit, state }, payload) {
    try {
      const response = await axiosInstance.post("/bots", payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async linkBotToChannels({ commit, state }, payload) {
    try {
      const response = await axiosInstance.post(
        `/bots/${payload.botId}/channels`,
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
