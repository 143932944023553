<template>
  <div>
    <div class="dropdown">
      <button
        type="button"
        class="wb-shortcodes__list-button dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        ref="dropdown_button"
      >
        Add Contact <i class="bi bi-plus wb-shortcodes__list-icon"></i>
      </button>
      <form
        class="dropdown-menu wb-dropdown-menu wb-dropdown-menu--extend py-3 px-2"
        ref="dropdown_menu"
      >
        <ContactList @selected="selectContact" />
      </form>
    </div>
  </div>
</template>

<script>
import ContactList from "@/components/Dashboard/Contacts/ContactList";
import { eventBus } from "@/utils/eventBus";
export default {
  name: "AddContact",
  components: {
    ContactList,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    createNewContact() {
      eventBus.emit("createNewContact");
    },
    selectContact(contact) {
      const dropbutton = this.$refs.dropdown_button;
      const dropMenu = this.$refs.dropdown_menu;
      dropbutton.classList.remove("show");
      dropMenu.classList.remove("show");
      const newContact = {
        type: "contact",
        label: "Add Contact",
        value: `@[${contact.name}](${contact.number})`,
        formatted: {
          name: contact.name,
          number: contact.number,
        },
      };
      this.$emit("contactAdded", newContact);
    },
  },
};
</script>

<style></style>
