import axiosInstance from "@/http";
import router from "@/router";
const state = {
  user: null,
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
};

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  getUser: (state) => state.user,
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setIsAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken;
  },
  setRefToken(state, refreshToken) {
    state.refreshToken = refreshToken;
  },
};

const actions = {
  async login({ commit, state }, credentials) {
    try {
      const response = await axiosInstance.post("/sessions", credentials);
      const tokens = response.data;
      await commit("setAccessToken", tokens.accessToken);
      await commit("setRefToken", tokens.refreshToken);
      await commit("setIsAuthenticated", true);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getAccount({ commit, state }) {
    try {
      const response = await axiosInstance.get("/users");
      const user = response.data.data;
      await commit("setUser", user);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async register({ commit }, credentials) {
    try {
      const response = await axiosInstance.post("/users", credentials);
      const data = response.data;
      commit("setUser", data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async verifyEmail({ commit }, credentials) {
    try {
      await axiosInstance.post("/email/check", credentials);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async requestCode({ commit }, credentials) {
    try {
      await axiosInstance.post("/email/verify", credentials);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async passwordReset({ commit }, credentials) {
    try {
      const res = await axiosInstance.post("/password/reset", credentials);
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async changePassword({ commit }, credentials) {
    try {
      await axiosInstance.post("/password/change", credentials);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async logout({ commit }) {
    try {
      await axiosInstance.delete("/sessions");
      router.push({
        path: "/",
      });
      commit("setAccessToken", null);
      commit("setRefToken", null);
      commit("setIsAuthenticated", false);
      commit("setUser", null);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getUserAccount({ commit }) {
    try {
      const res = await axiosInstance.get("/users");
      commit("setUser", res.data.data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async updateUserAccount({ commit, dispatch }, payload) {
    try {
      await axiosInstance.put("/users", payload);
      dispatch("getUserAccount");
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async updateUserAccountPassword({ commit, dispatch }, payload) {
    try {
      await axiosInstance.put("/users/password", payload);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
