<template>
  <div class="pt-0 pb-4">
    <hr class="wb-divider" />
    <div v-if="messageSent" class="pb-4 pt-5 px-4">
      <div class="wb-lottie-item wb-lottie-item--small">
        <LottieAnimation
          ref="anim"
          :animationData="require('@/assets/lottie/whatsapp.json')"
          :loop="false"
          :speed="1"
          :autoPlay="true"
        />
      </div>
      <div class="text-center">
        <h3 class="text__body--large text--dark text--semibold">
          Message Sent
        </h3>
        <p class="text__body--small text--dark">
          Your message has been sent successfully
        </p>
        <div class="text-center my-4">
          <span class="text__body--small text--dark">
            <a
              href="javascript:void(0)"
              @click="messageSent = false"
              class="text--green text-decoration-none"
              >Send More Messages</a
            ></span
          >
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="d-flex justify-content-center pt-5">
      <Spinner
        variant="layout"
        message="Sending message... wait a little"
        :hasMessage="true"
      />
    </div>
    <div v-if="!isLoading && !messageSent">
      <div class="" v-if="activeBotInstance">
        <div class="row align-items-start">
          <div class="col-lg-4 my-2">
            <div class="d-block align-items-center">
              <label
                for=""
                class="wb-input-label text__body--small my-2 text--secondary"
                >Broadcast type</label
              >
              <div class="dropdown-center">
                <button
                  class="wb-select-dropdown wb-select-dropdown--small dropdown-toggle w-100 text-start text-capitalize"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ sendType }}
                  <i class="bi bi-chevron-down"></i>
                </button>
                <ul
                  class="dropdown-menu wb-dropdown-menu wb-dropdown-menu--form dropdown-menu-end"
                >
                  <li v-for="(item, index) in sendTypes" :key="index">
                    <a
                      class="dropdown-item text-capitalize"
                      :class="{
                        'dropdown-item--active': sendType && sendType == item,
                      }"
                      href="javascript:void(0)"
                      @click="setSendType(item)"
                      >{{ item }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-8 my-2">
            <div class="d-block align-items-center">
              <label
                for=""
                class="wb-input-label text__body--small my-2 text--secondary"
                >To
                <span v-if="sendType == 'individual'">(press enter to add)</span
                >:</label
              >
              <div v-if="sendType == 'individual'">
                <div
                  class="input-group wb-input-group wb-input-group--small w-100 align-items-center"
                >
                  <input
                    class="wb-input form-control"
                    v-model="chatId"
                    placeholder="Number with intl. prefix"
                    @keyup.enter="
                      setContact({
                        name: 'NO NAME',
                        number: chatId,
                      })
                    "
                  />
                  <a
                    class="input-group-text dropdown-toggle"
                    id="basic-addon1"
                    href="javascript:void(0)"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    ref="dropdown_button"
                  >
                    <i class="bi bi-journals"></i>
                  </a>

                  <form
                    class="dropdown-menu dropdown-menu-end wb-dropdown-menu wb-dropdown-menu--form py-3 px-2"
                    ref="dropdown_menu"
                  >
                    <ContactList
                      @selected="setContact"
                      :activeContact="activeId"
                    />
                  </form>
                </div>
              </div>
              <div class="dropdown-center" v-else>
                <button
                  class="wb-select-dropdown wb-select-dropdown--small dropdown-toggle w-100 text-start text-capitalize"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ group ? group.title : "Select Group" }}
                  <i class="bi bi-chevron-down"></i>
                </button>
                <ul
                  class="dropdown-menu wb-dropdown-menu wb-dropdown-menu--form dropdown-menu-end py-3 px-2"
                >
                  <GroupList @selected="setGroup" :activeGroup="activeId" />
                </ul>
              </div>
              <InlineError
                message="Phone number already added"
                v-if="chatId && phoneExist"
              />
              <InlineError
                message="Provide a valid phone number"
                v-if="chatId && invalidPhone"
              />
            </div>
          </div>
        </div>

        <div
          class="mb-4 d-flex align-items-center flex-wrap"
          v-if="selectedContacts.length > 0 && sendType == 'individual'"
        >
          <TransitionGroup
            name="list"
            tag="div"
            class="wb-contact-pill-wrapper"
          >
            <div
              class="wb-contact-pill bg--green text-white"
              v-for="(selected, index) in selectedContacts"
              :key="selected.number"
              @click="removeContact(index)"
            >
              {{ selected.name }} ({{ selected.number }})
              <i class="bi bi-x"></i>
            </div>
          </TransitionGroup>
          <div class="my-2">
            <a
              href="javascript:void(0)"
              class="text__body--small text-danger text-decoration-none"
              @click="clearAll"
              >Clear all <i class="bi bi-trash"></i
            ></a>
          </div>
        </div>
        <div class="row align-items-start my-4">
          <div class="col-lg-12">
            <label
              for=""
              class="wb-input-label text__body--small my-2 text--secondary"
              >Template</label
            >
            <div class="dropdown-center">
              <button
                class="wb-select-dropdown wb-select-dropdown--small dropdown-toggle w-100 text-start text-capitalize"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ template ? template.name : "Select Template" }}
                <i class="bi bi-chevron-down"></i>
              </button>
              <ul
                class="dropdown-menu wb-dropdown-menu wb-dropdown-menu--form dropdown-menu-end py-3 px-2"
              >
                <TemplateList />
              </ul>
            </div>
          </div>
        </div>
        <div class="form-group my-4">
          <label
            for=""
            class="wb-input-label text__body--small my-2 text--secondary"
            >Broadcast message</label
          >
          <div class="wb-textarea position-relative">
            <ShortCodes
              @codeAdded="codeAdded"
              @newButton="addButtonToList"
              :buttonCount="buttons.length"
            />
            <textarea
              placeholder="Enter message..."
              v-maxlength="5000"
              v-model="message"
            ></textarea>
            <a
              href="javascript:void(0)"
              class="text__body--small text--dark"
              @click="toggleEmojiPicker"
              ref="emojiToggleButton"
            >
              <i class="bi bi-emoji-smile"></i>
            </a>
            <div ref="emojiPicker" class="wb-emoji-picker">
              <EmojiPicker
                :native="true"
                v-if="showEmojiPicker"
                @select="onSelectEmoji"
              />
            </div>
            <div class="text-end position-absolute bottom-right">
              <span
                class="text__body--small text--semibold"
                :class="{
                  'text--green': remainingCharacters > 50,
                  'text-warning': remainingCharacters < 50,
                  'text-danger': remainingCharacters == 0,
                }"
              >
                {{ remainingCharacters }} Characters</span
              >
            </div>
          </div>
        </div>
        <InlineError
          message="Be sure to type in an actual message"
          v-if="message && v$.message.$invalid"
        />
        <div>
          <TransitionGroup name="list" tag="div" class="wb-file-item-wrapper">
            <div v-for="(file, index) in attachments" :key="index">
              <FileItem :file="file" :index="index" @removeFile="removeFile" />
            </div>
          </TransitionGroup>
          <div class="mt-3" v-if="buttons.length > 0">
            <div class="mb-2 text__body--small text-muted">Buttons</div>
            <TransitionGroup name="list" tag="div" class="wb-file-item-wrapper">
              <div
                v-for="(button, index) in buttons"
                :key="index"
                @click="removeButton(button)"
              >
                <button class="wb-button wb-button--small wb-button--info me-2">
                  {{ button.title }}
                  <i class="bi bi-x"></i>
                </button>
              </div>
            </TransitionGroup>
          </div>
          <InlineError
            message="You can only add 3 buttons"
            v-if="buttonExists"
          />
        </div>
        <div class="d-flex align-items-center justify-content-end mt-4">
          <CreateAttachment @filesAttached="getAttachments" />
          <div class="mx-2"></div>
          <button
            @click="sendMessage"
            class="wb-button wb-button--small wb-button--primary wb-button--no-shadow wb-button--rounded-full"
            :disabled="formInvalid || isLoading"
          >
            Send Broadcast
            <i class="bi bi-send-fill ms-2" v-if="!isLoading"></i>
            <Spinner v-else="isLoading" />
          </button>
        </div>
      </div>
      <div v-else>
        <EmptyState
          title="No bots available "
          description="You will be able to send a message when you create a bot"
          :icon="require('@/assets/icons/bots_scan.svg')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
import FileItem from "@/components/Global/FileItem";
import CountrySelect from "@/components/Global/CountrySelect";
import ContactModal from "@/components/Dashboard/Contacts/ContactModal.vue";
import GroupList from "@/components/Dashboard/Contacts/GroupList.vue";
import ShortCodes from "@/components/Dashboard/Messages/ShortCodes.vue";
import { LottieAnimation } from "lottie-web-vue";
import ContactList from "@/components/Dashboard/Contacts/ContactList";
import TemplateList from "@/components/Dashboard/Broadcast/Templates/TemplateList";
import validator from "validator";
import EmojiPicker from "vue3-emoji-picker";
const toast = useToast();
import { useHead } from "unhead";
export default {
  name: "SendMessage",
  components: {
    EmojiPicker,
    FileItem,
    CountrySelect,
    ContactModal,
    GroupList,
    LottieAnimation,
    ShortCodes,
    ContactList,
    TemplateList,
  },
  setup() {
    useHead({
      title: "Send Message",
    });
    return { v$: useVuelidate() };
  },
  data() {
    return {
      sendTypes: ["individual", "group"],
      sendType: "individual",
      message: "",
      chatId: "",
      country: {
        phone: "234",
        code: "NG",
      },
      group: null,
      template: null,
      activeId: "",
      attachments: [],
      selectedContacts: [],
      buttons: [],
      isLoading: false,
      messageSent: false,
      invalidPhone: false,
      phoneExist: false,
      buttonExists: false,
      showEmojiPicker: false,
    };
  },
  validations() {
    return {
      sendType: { required },
      message: { required },
      chatId: { required, minLength: minLength(10) },
    };
  },
  mounted() {
    const { type, id } = this.$route.query;
    if (type && id && (type == "group" || type == "individual")) {
      this.sendType = type;
      this.activeId = id;
    }
    document.addEventListener("click", this.handleClickOutside);
  },

  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },

  computed: {
    ...mapGetters(["activeBotInstance"]),
    botInstance() {
      return this.activeBotInstance ? this.activeBotInstance : false;
    },
    remainingCharacters() {
      const maxLength = 5000;
      const currentLength =
        this.message && !this.v$.message.$invalid ? this.message.length : 0;
      return maxLength - currentLength;
    },
    formInvalid() {
      if (this.selectedContacts.length > 0) {
        return this.v$.message.$invalid || !this.toContacts;
      } else {
        return this.v$.message.$invalid || !this.group;
      }
    },

    toContacts() {
      if (this.selectedContacts.length === 0) {
        return null;
      }
      return this.selectedContacts.map((contact) => contact.number).join(",");
    },
    fileAttachmentIds() {
      if (this.attachments.length === 0) {
        return [];
      }
      return this.attachments.map((file) => file._id);
    },
  },
  methods: {
    ...mapActions(["sendBroadCastMessage"]),
    handleClickOutside(event) {
      const emojiPickerElement = this.$refs.emojiPicker;
      const emojiToggleButton = this.$refs.emojiToggleButton;
      if (emojiPickerElement && emojiToggleButton) {
        if (
          !emojiPickerElement.contains(event.target) &&
          !emojiToggleButton.contains(event.target)
        ) {
          this.showEmojiPicker = false;
        }
      }
    },
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    onSelectEmoji(emoji) {
      this.message += `${emoji.i}`;
    },
    codeAdded(code) {
      this.message += `${code.value} `;
    },
    addButtonToList(button) {
      this.buttonExists = false;

      if (this.buttons.length >= 3) {
        this.buttonExists = true;
        return;
      }
      this.buttons.push(button);
    },
    removeButton(button) {
      const index = this.buttons.findIndex((btn) => btn === button);
      if (index !== -1) {
        this.buttons.splice(index, 1);
        console.log("Button removed:", button);
      }
    },
    clearQueryParams() {
      const route = { ...this.$route };
      if (Object.keys(route.query).length > 0) {
        route.query = {};
        this.$router.push(route);
      }
    },
    clearAll() {
      this.selectedContacts = [];
      this.clearQueryParams();
    },
    resetForm() {
      this.selectedContacts = [];
      this.attachments = [];
      this.message = "";
      this.group = null;
      this.clearQueryParams();
    },
    showCountrySelect() {
      this.$refs.country_select.openModal();
    },
    showContactModal() {
      this.$refs.contact_modal.openModal();
    },
    showGroupModal() {
      this.$refs.group_modal.openModal();
    },
    setGroup(group) {
      this.group = group;
    },
    setCountryCode(country) {
      this.country = {
        code: country.code,
        phone: country.phone,
      };
    },
    setContact(contact) {
      console.log(contact);
      this.invalidPhone = false;
      this.phoneExist = false;
      const countryCode = this.country ? this.country.phone : "";
      console.log(countryCode);
      const number = contact && contact.number ? contact.number.trim() : "";

      if (number !== "" && validator.isMobilePhone(number)) {
        const numberExists = this.selectedContacts.some(
          (selectedContact) => selectedContact.number === number
        );

        if (!numberExists) {
          let formattedNumber = number.replace(/[^0-9]/g, "");
          // if (countryCode && !formattedNumber.startsWith(countryCode)) {
          //   formattedNumber = countryCode + formattedNumber;
          // }

          this.selectedContacts.push({
            ...(contact || {}),
            number: formattedNumber,
          });
          this.chatId = "";
          this.closeDropdown();
        } else {
          console.log("Contact number already exists.");
          this.phoneExist = true;
          this.closeDropdown();
        }
      } else {
        console.log("Invalid phone number.");
        this.invalidPhone = true;
        this.closeDropdown();
      }
    },
    closeDropdown() {
      const dropbutton = this.$refs.dropdown_button;
      const dropMenu = this.$refs.dropdown_menu;
      dropbutton.classList.remove("show");
      dropMenu.classList.remove("show");
    },
    removeContact(index) {
      this.selectedContacts.splice(index, 1);
    },

    async sendMessage() {
      if (this.botInstance) {
        if (this.isLoading) {
          return;
        }
        this.isLoading = true;
        try {
          const payload = {
            session_id: this.botInstance.session_id,
            access_token: this.botInstance.access_token,
            send_type: this.sendType,
            message: {
              text: this.message.trim(),
              attachments: this.fileAttachmentIds,
            },
          };
          if (this.sendType == "individual") {
            payload.to = this.toContacts;
          } else {
            payload.to = this.group._id;
          }
          await this.sendBroadCastMessage(payload);
          this.messageSent = true;
          this.resetForm();
        } catch (error) {
          console.log(error);
          toast.error("An error occurred. Please try again");
        } finally {
          this.isLoading = false;
        }
      }
    },
    getAttachments(files) {
      if (files && files.length > 0) {
        files.forEach((file) => {
          this.attachments.push(file);
        });
      }
    },
    setSendType(type) {
      this.sendType = type;
    },
    removeFile(index) {
      this.attachments.splice(index, 1);
    },
  },
};
</script>

<style></style>
