export const AccountRoutes = [
  {
    path: "/u/account/profile",
    // name: "Profile",
    component: () => import("@/views/Dashboard/Account/Index"),
    children: [
      {
        path: "/u/account/profile",
        name: "Profile",
        component: () => import("@/views/Dashboard/Account/Profile"),
      },
      {
        path: "/u/account/profile/edit",
        name: "ProfileEdit",
        component: () => import("@/views/Dashboard/Account/ProfileEdit"),
      },
      {
        path: "/u/account/password",
        name: "AccountPassword",
        component: () => import("@/views/Dashboard/Account/AccountPassword"),
      },
      {
        path: "/u/account/billing",
        name: "Billing",
        component: () => import("@/views/Dashboard/Account/BillingManagement"),
      },
    ],
  },
];
