<template>
  <div>
    <button
      class="wb-button wb-button--small wb-button--primary"
      @click="openModal"
      :class="{
        'w-100': buttonFull,
      }"
    >
      Add Account
    </button>
    <ModalWrapper
      v-if="showCreate"
      modalTitle="Create new account"
      :hasClose="true"
      @closeClicked="closeModal"
    >
      <form @submit.prevent="initBot">
        <!-- <div class="form-group my-3" style="display: none;">
          <label for="" class="wb-input-label">Account Type</label>
          <div class="dropdown-center">
            <button
              class="wb-select-dropdown dropdown-toggle w-100 text-start"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ botType ? botType : "Select Account Type" }}
              <i class="bi bi-chevron-down"></i>
            </button>
            <ul
              class="dropdown-menu wb-dropdown-menu wb-dropdown-menu--form dropdown-menu-end"
            >
              <li v-for="(item, index) in botTypes" :key="index">
                <a
                  class="dropdown-item"
                  :class="{
                    'dropdown-item--active': botType && botType == item,
                  }"
                  href="javascript:void(0)"
                  @click="setBotType(item)"
                  >{{ item }}</a
                >
              </li>
            </ul>
          </div>
        </div> -->
        <div class="form-group my-3">
          <label for="botName" class="wb-input-label">Bot Name</label>
          <div class="input-group wb-input-group mb-3">
            <input
              type="text"
              class="form-control wb-input"
              id="botName"
              v-model="botName"
            />
          </div>
          <InlineError
            message="Bot name should be between 4 - 18 Characters"
            v-if="botName && v$.botName.$invalid"
          />
        </div>
        <div class="my-4" style="display: none">
          <label class="wb-checkbox text__body--small text--dark">
            I agree to comply with the
            <a
              href="https://www.whatsapp.com/legal/terms-of-service"
              class="text--green"
              target="_blank"
              >WhatsApp terms</a
            >
            regarding spam or unsolicited messaging
            <input type="checkbox" checked="checked" v-model="agreeToTerms" />
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="mt-5">
          <button
            class="wb-button wb-button--primary w-100"
            :disabled="formInvalid || isLoading"
            type="submit"
          >
            Continue <Spinner v-if="isLoading" />
          </button>
        </div>
      </form>
    </ModalWrapper>
  </div>
</template>

<script>
import { ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength, maxLength } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
const toast = useToast();
import { mapActions, useStore } from "vuex";
export default {
  name: "CreateNewBot",
  props: {
    buttonFull: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    console.log({ store });
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      botName: "",
      botType: "",
      botTypes: ["Whatsapp"],
      isLoading: false,
      agreeToTerms: true,
      showCreate: false,
    };
  },
  validations() {
    return {
      botName: { required, minLength: minLength(4), maxLength: maxLength(18) },
      botType: { required },
    };
  },
  computed: {
    formInvalid() {
      return (
        this.v$.botName.$invalid ||
        // this.v$.botType.$invalid ||
        !this.agreeToTerms
      );
    },
  },
  methods: {
    ...mapActions(["createNewWhatsapp", "initializeWhatsapp"]),
    openModal() {
      this.$emit("openedBotCreation");
      this.showCreate = true;
    },
    closeModal() {
      this.showCreate = false;
      this.botName = "";
      this.botType = "whatsapp";
      this.$emit("closedBotCreation");
    },
    setBotType(type) {
      this.botType = type;
    },
    async initBot() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      try {
        const res = await this.createNewWhatsapp({
          bot_name: this.botName,
        });
        const newBot = res.data.data;

        await this.$store.commit("setInstance", newBot);
        console.log("switching bots");

        // await this.initializeWhatsapp({
        //   session_id: newBot.session_id,
        //   access_token: newBot.access_token,
        //   config: {
        //     multiDevice: true,
        //     setWebhook: true,
        //     webhookUrl: "",
        //   },
        // });
        this.closeModal();
        // toast.success("Account created... Redirecting to dashboard");
        this.$router.push({
          name: "AddChannel",
        });
      } catch (error) {
        console.log(error);
        toast.error("An error occurred while creating bot. Please try again");
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
