<template>
  <div>
    <button
      class="wb-button wb-button--small wb-button--plain"
      @click="openModal"
    >
      Import <i class="bi bi-cloud-plus-fill ms-2"></i>
    </button>
    <modal-wrapper
      :hasClose="!isUploading"
      @closeClicked="closeModal"
      v-if="showModal"
      modalTitle="Import CSV"
    >
      <div class="d-flex justify-content-center" v-if="resultReady">
        <p class="text__body--small text--secondary mb-0">
          Imported : {{ importResults.imported }} | Duplicate:
          {{ importResults.duplicate }}
        </p>
      </div>
      <div class="wb-file-attachment" v-if="!isUploading && !resultReady">
        <div class="text-center">
          <i class="bi bi-file-arrow-up text__body--x-large text--green"></i>
          <p class="text__body--small text--secondary mb-0">
            Click to upload or drag and drop file here
          </p>
          <p class="text__body--smaller text-muted">(CSV, up to 10mb)</p>
        </div>
        <input
          v-if="uploadReady"
          :disabled="isUploading"
          type="file"
          class="wb-file-attachment__input"
          ref="fileInput"
          accept=".csv"
          @change="uploadFile"
        />
      </div>
      <InlineError
        v-for="(error, index) in errors"
        :key="index"
        :message="error"
      />
      <div class="d-flex justify-content-center" v-if="isUploading">
        <Spinner
          variant="layout"
          :message="`${progress}%`"
          :hasMessage="true"
        />
      </div>
      <div v-if="files.length > 0" class="mt-4">
        <div>
          <TransitionGroup name="list" tag="div" class="wb-file-item-wrapper">
            <div v-for="(file, index) in files" :key="index">
              <FileItem :file="file" :index="index" @removeFile="removeFile" />
            </div>
          </TransitionGroup>
        </div>
        <div class="d-flex justify-content-center mt-4">
          <button
            class="wb-button wb-button--primary wb-button--small w-100"
            @click="attachFiles"
          >
            Import
          </button>
        </div>
      </div>
    </modal-wrapper>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import FileItem from "@/components/Global/FileItem";
import { mapGetters } from "vuex";
import axiosInstance from "@/http";
import { eventBus } from "@/utils/eventBus";
const toast = useToast();
export default {
  name: "Import",
  components: {
    FileItem,
  },
  props: {
    importType: {
      type: String,
      default: "",
    },
    surveyId: {
      type: String,
      default: "",
    },
    groupId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showModal: false,
      uploadReady: true,
      isUploading: false,
      resultReady: false,
      importResults: { duplicate: 0, imported: 0, duplicate: 0 },
      progress: 0,
      files: [],
      errors: [],
    };
  },
  computed: {
    ...mapGetters(["activeBotInstance"]),
    botInstance() {
      return this.activeBotInstance ? this.activeBotInstance : null;
    },
  },
  created() {
    eventBus.on("importStatus", (data) => {
      this.resultReady = true;
      this.isUploading = false;
      this.importResults = data;
      // console.log(data)
    });
  },
  methods: {
    initImport() {
      console.log("Import started");
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.files = [];
      this.resetState();
    },
    async uploadFile() {
      if (this.botInstance) {
        const fileInput = this.$refs.fileInput;
        if (fileInput.files.length > 0) {
          const file = fileInput.files[0];

          if (!this.validateFile(file)) {
            this.resetState();
            return;
          }

          const formData = new FormData();
          formData.append("file", file);

          this.isUploading = true;

          try {
            const response = await axiosInstance.post(
              `/csv/upload?session_id=${
                this.botInstance.session_id
              }&access_token=${this.botInstance.access_token}&type=${
                this.importType
              }&group_id=${this.groupId || ""}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                  const progress = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  this.progress = progress;
                },
              }
            );

            const uploadedFile = response.data.data;
            const filename = file.name;
            const fileextension = filename.substring(
              filename.lastIndexOf(".") + 1
            );
            const fileIcon = this.getFileIconClass(fileextension);

            this.files.push({
              ...uploadedFile,
              filename,
              fileextension,
              fileIcon,
            });

            this.resetState();
          } catch (error) {
            console.error(error);
            this.resetState();
          } finally {
            this.isUploading = true;
            toast.info(
              "Importing is beign done at the background, you will be notified shortly!"
            );
            this.$emit("importCompleted");
            this.closeModal();
          }
        }
      }
    },

    getFileIconClass(fileextension) {
      const iconClassMap = {
        pdf: "bi bi-file-pdf-fill",
        png: "bi bi-image-fill",
        jpeg: "bi bi-image-fill",
        jpg: "bi bi-image-fill",
        mp4: "bi bi-camera-video",
        mp3: "bi bi-mic-fill",
        wav: "bi bi-mic-fill",
      };

      const defaultIconClass = "bi bi-file-earmark-fill";

      const lowercaseExtension = fileextension.toLowerCase();
      return iconClassMap[lowercaseExtension] || defaultIconClass;
    },
    attachFiles() {
      this.$emit("filesAttached", this.files);
      this.closeModal();
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    resetState() {
      this.uploadReady = false;
      this.progress = 0;
      this.resultReady = false;
      this.$nextTick(() => {
        this.uploadReady = true;
      });
    },
    validateFile(file) {
      const allowedTypes = ["text/csv"];
      this.errors = [];

      if (!allowedTypes.includes(file.type)) {
        this.errors.push("Invalid file type. Only CSV files are allowed.");
        return false;
      }

      const maxSize = 10 * 1024 * 1024;

      if (file.size > maxSize) {
        this.errors.push("File size exceeds the maximum limit (10MB)");
        return false;
      }

      return true;
    },
  },
};
</script>

<style></style>
