export const BotRoutes = [
  {
    path: "/u/channels",
    component: () => import("@/views/Dashboard/Channels/Index.vue"),
    children: [
      {
        path: "/u/channels",
        name: "Channels",
        component: () => import("@/views/Dashboard/Channels/Overview"),
      },
      {
        path: "/u/channels/new",
        meta: {
          requiresSub: true,
        },
        name: "AddChannel",
        component: () => import("@/views/Dashboard/Channels/Create/Index"),
      },
      {
        path: "/u/channels/whatsapp",
        meta: {
          requiresSub: true,
        },
        name: "AddWhatsappChannel",
        component: () =>
          import("@/views/Dashboard/Channels/Create/AddWhatsAppChannel.vue"),
      },
    ],
  },
  {
    path: "/u/dashboard/bot/active",
    name: "ActiveBot",
    component: () => import("@/views/Dashboard/Channels/ActiveBot.vue"),
  },
  {
    path: "/u/dashboard/bot/connect",
    name: "BotConnection",
    component: () => import("@/views/Dashboard/Bots/BotConnection.vue"),
  },
  {
    path: "/u/dashboard/bot/settings",
    name: "BotSettings",
    component: () => import("@/views/Dashboard/Bots/BotSettings.vue"),
  },
];
