<template>
  <div>
    <div class="d-block align-items-center">
      <div
        class="input-group wb-input-group wb-input-group--small wb-input-group--rounded w-100 me-3"
      >
        <input
          inputmode="text"
          v-model="searchQuery"
          type="tel"
          class="form-control wb-input"
          placeholder="Search Countries"
        />

        <a href="javascript:void(0)" class="input-group-text">
          <i class="bi bi-search"></i>
        </a>
      </div>
    </div>
    <div class="wb-list wb-list--small">
      <div
        v-for="country in filteredList"
        :key="country.code"
        class="d-flex align-items-start wb-list__item"
        @click="selectCountry(country)"
      >
        <div class="me-2">
          <span
            class="fi mr-2"
            :class="['fi-' + country.code.toLowerCase()]"
          ></span>
        </div>
        <div>
          <div class="text__body--smaller text--secondary">
            {{ country[labelToShow] }}
          </div>
          <div class="text__body--small text--secondary">
            {{ country[itemToShow] }}
          </div>
        </div>
      </div>
      <EmptyState
        v-if="filteredList.length === 0"
        title="No countries match your search"
        description="Be sure to enter a valid country"
        :icon="require('@/assets/icons/country_flag.svg')"
      />
    </div>
  </div>
</template>

<script>
import { COUNTRIES } from "@/utils/countryList";
export default {
  name: "CountrySelect",
  props: {
    itemToShow: {
      type: String,
      default: "phone",
    },
    labelToShow: {
      type: String,
      default: "name",
    },
  },
  data() {
    return {
      countries: COUNTRIES,
      showModal: false,
      searchQuery: "",
    };
  },
  computed: {
    filteredList() {
      const searchTerm = this.searchQuery.trim().toLowerCase();

      if (searchTerm !== "") {
        return this.countries
          .filter((c) => {
            const nameMatch =
              c.name && c.name.toLowerCase().includes(searchTerm);
            const codeMatch =
              c.code && c.code.toLowerCase().includes(searchTerm);
            const code3Match =
              c.code3 && c.code3.toLowerCase().includes(searchTerm);

            return nameMatch || codeMatch || code3Match;
          })
          .sort((a, b) => {
            const nameA = a.name || "";
            const nameB = b.name || "";
            return nameA.localeCompare(nameB);
          })
          .reduce((uniqueList, country) => {
            const existingCountry = uniqueList.find(
              (c) => c.code === country.code && c.code3 === country.code3
            );
            if (!existingCountry) {
              uniqueList.push(country);
            }
            return uniqueList;
          }, []);
      }

      return this.countries
        .sort((a, b) => {
          const nameA = a.name || "";
          const nameB = b.name || "";
          return nameA.localeCompare(nameB);
        })
        .reduce((uniqueList, country) => {
          const existingCountry = uniqueList.find(
            (c) => c.code === country.code && c.code3 === country.code3
          );
          if (!existingCountry) {
            uniqueList.push(country);
          }
          return uniqueList;
        }, []);
    },
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.searchQuery = "";
    },
    selectCountry(country) {
      this.$emit("selected", country);
      this.closeModal();
    },
  },
};
</script>

<style></style>
