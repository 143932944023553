export const BroadcastRoutes = [
  {
    path: "/u/dashboard/broadcast",
    name: "Broadcast",
    component: () => import("@/views/Dashboard/Broadcast/Index.vue"),
  },
  {
    path: "/u/dashboard/broadcast/templates",
    name: "Templates",
    component: () => import("@/views/Dashboard/Broadcast/Templates.vue"),
  },
  {
    path: "/u/dashboard/broadcast/templates/whatsapp/new",
    name: "NewWhatsappTemplate",
    component: () =>
      import("@/views/Dashboard/Broadcast/Whatsapp/NewWhatsapp.vue"),
  },
];
