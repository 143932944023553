import { createRouter, createWebHistory } from "vue-router";
import useSubscribe from "@/hooks/useSubscribe";
import store from "@/store";
import { routes } from "./routes";

const showSubscription = useSubscribe();

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.state?.auth?.isAuthenticated;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresSub = to.matched.some((record) => record.meta.requiresSub);

  if (requiresAuth) {
    if (!isAuthenticated) {
      next({ name: "Login" });
    } else {
      next();
      // const hasSub = store.state?.payment?.setSubscriptionState || false;
      // if (requiresSub && !hasSub) {
      //   const isSubscribed = await showSubscription("Consider our pro plan");
      //   if (isSubscribed) {
      //     next();
      //   } else {
      //     next({
      //       name: "Channels",
      //     });
      //   }
      // } else {
      //   next();
      // }
    }
  } else {
    next();
  }
});

export default router;
