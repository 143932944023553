import axiosInstance from "@/http";
const state = {};
const getters = {};
const mutations = {};
const actions = {
  async contactsGraphs({ commit, state }, payload) {
    try {
      const url = `/messaging/contact/graph?session_id=${payload.session_id}&access_token=${payload.access_token
        }&page=${payload.page}&limit=${payload.limit}&timeframe=${payload.timeframe || ""
        }`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async messagesGraphs({ commit, state }, payload) {
    try {
      const url = `/messaging/message/graph?session_id=${payload.session_id}&access_token=${payload.access_token
        }&page=${payload.page}&limit=${payload.limit}&timeframe=${payload.timeframe || ""
        }`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getAllContacts({ commit, state }, payload) {
    try {
      const url = payload.isAll
        ? `/messaging/contact?type=all&limit=${payload.limit}`
        : `/messaging/contact?session_id=${payload.session_id}&access_token=${payload.access_token
        }&page=${payload.page}&limit=${payload.limit}&group_id=${payload.group_id || ""
        }`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getContactGroups({ commit, state }, payload) {
    try {
      const url = `/messaging/contact-group?session_id=${payload.session_id}&access_token=${payload.access_token}&page=${payload.page}&limit=${payload.limit}`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async createNewContact({ commit, state }, payload) {
    try {
      const response = await axiosInstance.post("/messaging/contact", payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async createNewContactGroup({ commit, state }, payload) {
    try {
      const response = await axiosInstance.post("/messaging/contact-group", payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async deleteContact({ commit, state }, payload) {
    try {
      const response = await axiosInstance.delete(
        `/messaging/contact/${payload._id}?session_id=${payload.session_id}&access_token=${payload.access_token}`
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async deleteContactGroup({ commit, state }, payload) {
    try {
      const response = await axiosInstance.delete(
        `/messaging/contact-group/${payload._id}?session_id=${payload.session_id}&access_token=${payload.access_token}`
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async updateContact({ commit, state }, payload) {
    try {
      const response = await axiosInstance.put(
        `/messaging/contact/${payload._id}?session_id=${payload.session_id}&access_token=${payload.access_token}`,
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async updateContactGroup({ commit, state }, payload) {
    try {
      const response = await axiosInstance.put(
        `/messaging/contact-group/${payload._id}?session_id=${payload.session_id}&access_token=${payload.access_token}`,
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
