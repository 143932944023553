export const AutomationRoutes = [
  {
    path: "/u/automation",
    name: "Automation",
    component: () => import("@/views/Dashboard/Automation/Index.vue"),
  },
  {
    path: "/u/automation/new",
    name: "NewAutomation",
    component: () => import("@/views/Dashboard/Automation/NewAutomation"),
  },
];
