export const DeveloperRoutes = [
  {
    path: "/u/developer/api",
    name: "Developer",
    component: () => import("@/views/Dashboard/Developer/Index.vue"),
    meta: {
      extendedMenu: [
        {
          name: "API",
          path: "/u/developer/api",
        },
        {
          name: "Webhook",
          path: "/u/developer/webhook",
        },
      ],
    },
    children: [
      {
        path: "/u/developer/api",
        name: "Api",
        component: () => import("@/views/Dashboard/Developer/Api.vue"),
      },
      {
        path: "/u/developer/webhook",
        name: "Webhook",
        component: () => import("@/views/Dashboard/Developer/Webhook.vue"),
      },
    ],
  },
];
