import axiosInstance from "@/http";
import { API_BASE } from "@/config";
const state = {};
const getters = {};
const mutations = {};
const actions = {
  async getAppPricing({ commit, state }) {
    try {
      const response = await axiosInstance.get("/payment/pricing");
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getCountries({ commit, state }) {
    try {
      const response = await axiosInstance.get("/payment/calculator/countries");
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getPackagePrices({ commit, state }) {
    try {
      const response = await axiosInstance.get("/payment/calculator/prices");
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async calculatePricing({ commit, state }, payload) {
    try {
      const response = await axiosInstance.post(
        "/payment/calculator/price",
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async initPricing({ commit, state }, payload) {
    try {
      const response = await axiosInstance.post("/payment/initialize", payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async createPricing({ commit, state }, payload) {
    try {
      const response = await axiosInstance.post("/payment/pricing", payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async checkForActiveSub({ commit, state }) {
    try {
      const response = await axiosInstance.get("/payment/subscription/check");
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getPaymentHistory({ commit, state }, payload) {
    try {
      const response = await axiosInstance.get(
        `/payment/histories?&page=${payload.page || 1}&limit=${
          payload.limit || 10
        }`
      );
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async cancelSubscription({ commit, state }, payload) {
    try {
      const response = await axiosInstance.delete(
        `/payment/subscription`,
        payload
      );
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
