<template>
  <div
    class="wb-modal"
    :class="{
      'wb-modal--full': fullScreen,
    }"
  >
    <div
      class="wb-modal__content"
      :class="{
        'wb-modal__content--large': contentLarge,
      }"
    >
      <div
        class="wb-modal__content-controls d-flex align-items-center justify-content-between"
        :class="[fullScreen && 'container mb-5']"
      >
        <div class="text__body--large text--dark text--medium text-center">
          {{ modalTitle }}
        </div>
        <a
          v-if="hasClose"
          href="javascript:void(0)"
          class="wb-modal__close"
          @click="emitClose"
        >
          <i class="bi bi-x"></i>
        </a>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalWrapper",
  props: {
    hasClose: {
      type: Boolean,
      default: false,
    },
    contentLarge: {
      type: Boolean,
      default: false,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default: "",
    },
  },
  methods: {
    emitClose() {
      this.$emit("closeClicked");
    },
  },
};
</script>

<style scoped>
.wb-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 4rem 1rem;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 10px 20px -10px rgb(0 0 0 / 20%);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  transform: scale(1);
  transition: 0.2s ease-in-out;
}

.wb-modal .wb-modal__content {
  position: relative;
  height: auto;
  width: 100%;
  max-width: 500px;
  background: var(--wb-white);
  z-index: 10;
  border-radius: var(--wb-radius-1);
  padding: 2rem;
}
.wb-modal .wb-modal__content.wb-modal__content--large {
  max-width: 1200px;
}
.wb-modal.wb-modal--full {
  padding: 0;
}
.wb-modal.wb-modal--full .wb-modal__content {
  max-width: 100%;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  border-radius: 0;
}
.wb-modal__content-controls {
  position: relative;
  margin-bottom: 1rem;
}
.wb-modal .wb-modal__close {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--wb-grey4);
  border-radius: 50%;
  color: var(--wb-dark);
  font-size: 1.3rem;
}
</style>
