import axiosInstance from "@/http";
import { API_BASE } from "@/config";
const handleExport = (url) => {
  window.open(url, "_blank");
};
const state = {};
const getters = {};
const mutations = {};
const actions = {
  async searchWaabot({ commit, state }, payload) {
    try {
      const url = `${API_BASE}/search?type=${payload.type}&session_id=${payload.session_id}&access_token=${payload.access_token}&search=${payload.query}&page=${payload.page}&limit=${payload.limit}`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getAnalytics({ commit, state }, payload) {
    try {
      const url = `${API_BASE}/analytic?type=${payload.type}&session_id=${payload.session_id}&access_token=${payload.access_token}`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getApiLogs({ commit, state }, payload) {
    try {
      const url = `${API_BASE}/api-logs?session_id=${
        payload.session_id
      }&access_token=${payload.access_token}&timeframe=${
        payload.timeframe || "daily"
      }`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getApiLogsGraph({ commit, state }, payload) {
    try {
      const url = `${API_BASE}/api-logs/graph?session_id=${
        payload.session_id
      }&access_token=${payload.access_token}&timeframe=${
        payload.timeframe || "daily"
      }`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getWebhookLogs({ commit, state }, payload) {
    try {
      const url = `${API_BASE}/webhook-logs?session_id=${
        payload.session_id
      }&access_token=${payload.access_token}&page=${payload.page || 1}&limit=${
        payload.limit || 10
      }`;
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async updateWebhookSettings({ commit, state }, { payload, id }) {
    try {
      const url = `${API_BASE}/bots/${id}`;
      const response = await axiosInstance.put(url, payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async exportAutoReply({ commit, state }, payload) {
    try {
      const url = `${API_BASE}/csv/download?type=intent&session_id=${payload.session_id}&access_token=${payload.access_token}`;
      await handleExport(url);
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async exportSurveyQuestions({ commit, state }, payload) {
    try {
      const url = `${API_BASE}/csv/download?type=survey-question&session_id=${payload.session_id}&access_token=${payload.access_token}&surveyId=${payload.surveyId}`;
      await handleExport(url);
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async exportContacts({ commit, state }, payload) {
    try {
      const url = `${API_BASE}/csv/download?type=contact&session_id=${payload.session_id}&access_token=${payload.access_token}`;
      await handleExport(url);
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async handleBulkDelete({ commit, state }, payload) {
    try {
      const url = `${API_BASE}/bulk-delete`;
      await axiosInstance.delete(url, { data: payload });
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
