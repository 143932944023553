<template>
  <div>
    <div class="dropdown">
      <button
        type="button"
        class="wb-shortcodes__list-button dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-auto-close="outside"
        ref="dropdown_button"
      >
        Share file <i class="bi bi-plus wb-shortcodes__list-icon"></i>
      </button>
      <form
        class="dropdown-menu wb-dropdown-menu wb-dropdown-menu--extend p-3"
        ref="dropdown_menu"
      >
        <div
          class="wb-file-attachment wb-file-attachment--small"
          v-if="!isUploading && !uploaded"
        >
          <div class="text-center p-3">
            <i class="bi bi-paperclip text__body--larger text--dark"></i>
            <p class="text__body--smaller text--secondary mb-0">
              Drag and drop file or <span class="text-info">click</span> to
              upload
            </p>
            <p class="text__body--smallest text-muted">
              (pdf, png, jpeg, mp4, up to 10mb)
            </p>
          </div>
          <input
            v-if="uploadReady"
            :disabled="isUploading"
            type="file"
            class="wb-file-attachment__input"
            ref="fileInput"
            accept="image/jpeg, image/png, video/mp4, application/pdf, audio/mpeg, audio/wav, audio/mp3"
            @change="uploadFile"
          />
        </div>
        <InlineError
          v-for="(error, index) in errors"
          :key="index"
          :message="error"
        />
        <div class="d-flex justify-content-center" v-if="isUploading">
          <Spinner
            variant="layout"
            :message="`${progress}%`"
            :hasMessage="true"
          />
        </div>
        <div v-if="files.length > 0 && uploaded">
          <div>
            <TransitionGroup name="list" tag="div" class="wb-file-item-wrapper">
              <div v-for="(file, index) in files" :key="index">
                <FileItem
                  :file="file"
                  :index="index"
                  @removeFile="removeFile"
                />
              </div>
            </TransitionGroup>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-5">
          <button
            class="wb-button wb-button--primary wb-button--small w-100"
            @click="attachFiles"
            :disabled="!uploaded"
            type="button"
          >
            Add File To Message
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axiosInstance from "@/http";
import { useToast } from "vue-toastification";
import FileItem from "@/components/Global/FileItem";
const toast = useToast();
export default {
  name: "AddFile",
  components: {
    FileItem,
  },
  data() {
    return {
      showModal: false,
      uploadReady: true,
      isUploading: false,
      progress: 0,
      uploaded: false,
      files: [],
      errors: [],
    };
  },
  computed: {
    ...mapGetters(["activeBotInstance"]),
    botInstance() {
      return this.activeBotInstance ? this.activeBotInstance : null;
    },
  },
  methods: {
    async uploadFile() {
      if (this.botInstance) {
        const fileInput = this.$refs.fileInput;
        if (fileInput.files.length > 0) {
          const file = fileInput.files[0];

          if (!this.validateFile(file)) {
            this.resetState();
            return;
          }

          const reader = new FileReader();

          reader.onload = async (e) => {
            const base64Data = e.target.result;

            const payload = {
              session_id: this.botInstance.session_id,
              access_token: this.botInstance.access_token,
              mimeType: file.type,
              file: base64Data,
            };
            this.isUploading = true;

            try {
              const response = await axiosInstance.post(
                "/attachment",
                payload,
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                  onUploadProgress: (progressEvent) => {
                    const progress = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    this.progress = progress;
                  },
                }
              );
              const uploadedFile = response.data.data;
              const filename = file.name;
              const fileextension = filename.substring(
                filename.lastIndexOf(".") + 1
              );
              const fileIcon = this.getFileIconClass(fileextension);
              this.files.push({
                ...uploadedFile,
                filename,
                fileextension,
                fileIcon,
              });
              this.uploaded = true;
            } catch (error) {
              console.error(error);
              this.resetState();
              toast.error("An error occurred. Please try again");
            } finally {
              this.isUploading = false;
            }
          };

          reader.readAsDataURL(file);
        }
      }
    },
    getFileIconClass(fileextension) {
      const iconClassMap = {
        pdf: "bi bi-file-pdf-fill",
        png: "bi bi-image-fill",
        jpeg: "bi bi-image-fill",
        jpg: "bi bi-image-fill",
        mp4: "bi bi-camera-video",
        mp3: "bi bi-mic-fill",
        wav: "bi bi-mic-fill",
      };

      const defaultIconClass = "bi bi-file-earmark-fill";

      const lowercaseExtension = fileextension.toLowerCase();
      return iconClassMap[lowercaseExtension] || defaultIconClass;
    },
    attachFiles() {
      const dropbutton = this.$refs.dropdown_button;
      const dropMenu = this.$refs.dropdown_menu;
      dropbutton.classList.remove("show");
      dropMenu.classList.remove("show");

      this.$emit("fileAttached", {
        type: "file",
        label: "Add file",
        value: `[file](${this.files[0].url})`,
        formatted: {
          ...this.files[0],
        },
      });
      this.resetState();
    },
    removeFile(index) {
      this.files.splice(index, 1);
      this.uploaded = false;
    },
    resetState() {
      this.uploadReady = false;
      this.progress = 0;
      this.files = [];
      this.uploaded = false;
      this.$nextTick(() => {
        this.uploadReady = true;
      });
    },
    validateFile(file) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "video/mp4",
        "application/pdf",
        " audio/mpeg",
        "audio/wav",
        "audio/mp3",
      ];
      this.errors = [];
      if (!allowedTypes.includes(file.type)) {
        this.errors.push(
          "Invalid file type. Only images, videos, and PDFs are allowed."
        );
        return false;
      }

      const maxSize = 10 * 1024 * 1024;

      if (file.size > maxSize) {
        this.errors.push("File size exceeds the maximum limit (10MB)");
        return false;
      }

      return true;
    },
  },
};
</script>

<style></style>
