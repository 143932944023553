<template>
  <div>
    <div
      class="wb-shortcodes"
      :class="{
        'wb-shortcodes--open': shortCodeOpen,
      }"
    >
      <div class="wb-shortcodes__list" v-if="shortCodeOpen">
        <button
          @click="addName"
          class="wb-shortcodes__list-button"
          type="button"
        >
          Add Name
          <i class="bi bi-plus wb-shortcodes__list-icon"></i>
        </button>
        <AddFile
          @fileAttached="selectCode"
          v-if="!exclude.includes('add_file')"
        />
        <AddContact
          @contactAdded="selectCode"
          v-if="!exclude.includes('add_contact')"
        />
        <AddButton
          @buttonAdded="addButton"
          :buttonCount="buttonCount"
          v-if="!exclude.includes('add_button')"
        />
        <AddLocation
          @locationAdded="selectCode"
          v-if="!exclude.includes('add_location')"
        />
      </div>
      <button
        type="button"
        class="wb-shortcodes__toggle"
        @click="toggleShortcodes"
        :class="[shortCodeOpen ? 'bg-danger' : 'bg--green']"
      >
        <i :class="[shortCodeOpen ? 'bi bi-x' : 'bi bi-plus']"></i>
      </button>
    </div>
  </div>
</template>

<script>
import AddFile from "./Shortcodes/AddFile.vue";
import AddContact from "./Shortcodes/AddContact.vue";
import AddButton from "./Shortcodes/AddButton.vue";
import AddLocation from "./Shortcodes/AddLocation.vue";
export default {
  name: "ShortCodes",
  components: {
    AddFile,
    AddContact,
    AddButton,
    AddLocation,
  },
  props: {
    buttonCount: {
      type: Number,
      default: 0,
    },
    exclude: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      shortCodeOpen: false,
    };
  },
  methods: {
    toggleShortcodes() {
      this.shortCodeOpen = !this.shortCodeOpen;
    },
    addName() {
      const name = {
        label: "Add Name",
        value: "[name]",
      };
      this.selectCode(name);
    },
    selectCode(code) {
      this.$emit("codeAdded", code);
    },
    addButton(button) {
      this.$emit("newButton", button);
    },
  },
};
</script>

<style></style>
