<template>
  <div>
    <div v-if="isLoading" class="d-flex justify-content-center">
      <Spinner
        variant="layout"
        message="Fetching Groups... Hang on."
        :hasMessage="true"
      />
    </div>
    <div v-if="!isLoading && !errorOccurred">
      <div class="d-block align-items-center px-2">
        <div class="input-group wb-input-group wb-input-group--small w-100">
          <input
            inputmode="text"
            v-model="searchQuery"
            type="tel"
            class="form-control wb-input"
            placeholder="Search Groups"
          />

          <a href="javascript:void(0)" class="input-group-text">
            <i class="bi bi-search"></i>
          </a>
        </div>
      </div>
    </div>
    <div
      class="wb-list wb-list--small"
      v-if="!isLoading && filteredGroups.length > 0"
    >
      <div
        v-for="group in filteredGroups"
        :key="group._id"
        class="my-3 wb-contact__item"
        @click="selectGroup(group)"
      >
        <div class="me-1">
          <i class="bi bi-people text--secondary text__body--large"></i>
        </div>
        <div>
          <div class="text__body--smaller text--secondary">
            {{ group.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="text-center" v-if="errorOccurred">
      <ErrorOccurred
        message=" An error occurred while fetching groups."
        description="There was an error while getting your groups"
        @retry="getGroups"
      />
    </div>
    <div
      class="text-center"
      v-if="!isLoading && filteredGroups.length == 0 && !errorOccurred"
    >
      <EmptyState
        title="No Groups available "
        description="Groups you create will appear here"
        :icon="require('@/assets/icons/contacts.svg')"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "GroupList",
  props: {
    activeGroup: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showModal: false,
      groups: [],
      searchQuery: "",
      isLoading: false,
      errorOccurred: false,
    };
  },
  mounted() {
    this.getGroups();
  },
  computed: {
    ...mapGetters(["activeBotInstance"]),
    botInstance() {
      return this.activeBotInstance ? this.activeBotInstance : false;
    },
    filteredGroups() {
      const searchTerm = this.searchQuery.trim();

      if (searchTerm !== "") {
        return this.groups.filter((c) => {
          const nameMatch =
            c.name && c.name.toLowerCase().includes(searchTerm.toLowerCase());

          return nameMatch;
        });
      }

      return this.groups;
    },
  },
  methods: {
    ...mapActions(["getContactGroups"]),
    computeActiveGroup() {
      const groupId = this.activeGroup;
      if (groupId && groupId.trim() !== "") {
        const activeGroup = this.groups.find((group) => group._id == groupId);
        if (activeGroup) {
          this.selectGroup(activeGroup);
        }
      }
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.searchQuery = "";
    },
    selectGroup(group) {
      this.$emit("selected", group);
      this.closeModal();
    },
    async getGroups() {
      if (this.isLoading || !this.activeBotInstance) {
        return;
      }
      this.isLoading = true;
      this.errorOccurred = false;
      try {
        const { data } = await this.getContactGroups({
          session_id: this.botInstance.session_id,
          access_token: this.botInstance.access_token,
          page: 1,
          limit: 1000000,
        });
        this.groups = data.data.results;
        this.computeActiveGroup();
      } catch (error) {
        console.log(error);
        this.errorOccurred = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
