<template>
  <div>
    <div class="dropdown">
      <button
        type="button"
        class="wb-shortcodes__list-button"
        @click="openLocationAttachment"
      >
        Add Location <i class="bi bi-plus wb-shortcodes__list-icon"></i>
      </button>
      <AttachLocation
        ref="location_attach"
        @locationAttached="handleLocationAttached"
      />
    </div>
  </div>
</template>

<script>
import AttachLocation from "@/components/Dashboard/Livechat/Attach/AttachLocation.vue";
export default {
  name: "AddLocation",
  components: {
    AttachLocation,
  },
  methods: {
    handleLocationAttached(location) {
      const locationName = location?.place_name || "Location";
      const latitude = location?.geometry?.coordinates[1];
      const longitude = location?.geometry?.coordinates[0];
      this.$emit("locationAdded", {
        type: "location",
        label: "Add Location",
        value: `[location](${longitude}|${latitude}|${locationName})`,
        formatted: {
          lng: longitude,
          lat: latitude,
          location: locationName,
        },
      });
    },
    openLocationAttachment() {
      this.$refs.location_attach.openModal();
    },
  },
};
</script>

<style></style>
