<template>
  <modal-wrapper
    v-if="showModal"
    modalTitle="Attach Location"
    :hasClose="true"
    @closeClicked="closeModal"
  >
    <div>
      <div
        class="d-flex align-items-center is--sticky is--sticky-top-header mb-4"
      >
        <form
          action=""
          method="post"
          @submit.prevent="searchLocation"
          class="flex--1 me-1"
        >
          <div
            class="input-group wb-input-group wb-input-group--small wb-input-group--auto w-100"
          >
            <input
              type="text"
              class="form-control wb-input text__body--smaller"
              v-model="searchQuery"
              placeholder="Search location"
            />

            <button
              class="wb-button wb-button--icon wb-button--plain"
              id="basic-addon1"
            >
              <i class="bi bi-search"></i>
            </button>
          </div>
        </form>
      </div>
      <div v-if="locations.length > 0">
        <div class="wb-list wb-list--small">
          <div
            v-for="location in locations"
            :key="location.id"
            class="wb-list__item d-flex align-items-center"
            @click="setLocationData(location)"
          >
            <div class="text__body--small text--dark text--regular me-2">
              <i class="bi bi-geo-alt"></i>
            </div>
            <div class="text__body--small text--dark text--regular">
              {{ location.place_name }}
            </div>
          </div>
        </div>
      </div>
      <Spinner
        variant="layout"
        position="center"
        :hasMessage="true"
        message="Fetching Location"
        v-if="isLoading"
      />
      <ErrorOccurred
        message=" An error occurred while fetching Location."
        description="There was an error while getting location data"
        v-if="errorOccurred && searchQuery"
        @retry="searchLocation"
      />
    </div>
  </modal-wrapper>
</template>

<script>
export default {
  name: "AttachLocation",
  data() {
    return {
      showModal: false,
      searchQuery: "",
      locationData: null,
      isLoading: false,
      errorOccurred: false,
      locations: [],
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
      this.searchQuery = "";
      this.locations = [];
    },
    openModal() {
      this.showModal = true;
    },
    setLocationData(location) {
      this.$emit("locationAttached", location);
      this.closeModal();
    },
    async searchLocation() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.errorOccurred = false;
      this.locations = [];
      try {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            this.searchQuery
          )}.json?access_token=pk.eyJ1Ijoib2d1cmlua2FiZW4iLCJhIjoiY2w1YTVxZXNzMDUxZzNibng2dDFnMnRsNiJ9.AvKqyclJniAli51dHEch0Q`
        );
        const data = await response.json();

        if (data.features && data.features.length > 0) {
          this.locations = data.features;
        }
      } catch (error) {
        console.error("Error searching location:", error);
        this.errorOccurred = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
